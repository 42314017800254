<template>
  <v-list-item link @click="click(linkTo)" color="white" dark>
    <v-list-item-icon>
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title>{{ title }}</v-list-item-title>
  </v-list-item>
</template>
<script>
export default {
  name: "DrawerItem",
  props: {
    icon: {
      type: String
    },
    title: {
      required: true,
      type: String
    },
    linkTo: {
      required: true,
      type: String
    }
  },
  methods: {
    click: function(goTo) {
      this.$vuetify.goTo(goTo, { duration: 1000 });
    }
  }
};
</script>
